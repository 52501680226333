import { endpointAdapterFactory, schemaMapperFactory } from '@empathyco/x-adapter';
import { getEndpointRoute } from '../../utils/environment';
import { MyMotiveRelatedPrompt } from '../models/entitites/result';

/**
 * Returns the store counter endpoint URL based on the current environment.
 *
 * Depending on the host of the current document location, it will concatenate
 * the appropriate environment variable with the store counter path.
 *
 * @returns The complete store counter endpoint URL.
 */
function getRelatedPromptEndpoint(): string {
  return `${getEndpointRoute()}relatedprompts/mymotivemarketplace`;
}

/**
 * Finds if the next query is included in the query.
 *
 * @param nextQuery - The next query tested.
 * @param query - The query to filter.
 * @returns True if the next query is included in the query.
 */
function findQueryIncludedInNextQueries(nextQuery: string, query: string): boolean {
  const queryParsed = query.toLowerCase().split(' ');
  const nextQueryLower = nextQuery.toLowerCase();
  return queryParsed.some((queryWord: string) => {
    return (
      nextQueryLower === queryWord ||
      nextQueryLower.includes(`${queryWord} `) ||
      nextQueryLower.includes(` ${queryWord}`)
    );
  });
}

/**
 * Maps the suggerstions ( RP ) from the response.
 *
 * @param relatedprompts - The info array.
 * @param query - The query to filter.
 * @param fallbackQuery - The fallback query.
 * @returns The mapped relatedprompts.
 */
function syntheticSuggestionsMapper(
  relatedprompts: Array<MyMotiveRelatedPrompt>,
  query: string,
  fallbackQuery: string
): Array<any> {
  return relatedprompts
    .map(relatedprompt => {
      return {
        ...relatedprompt,
        nextQueries: relatedprompt.nextQueries.filter((nextQuery: string) => {
          return nextQuery !== query && nextQuery !== fallbackQuery;
        })
      };
    })
    .filter(relatedprompt => {
      return relatedprompt.nextQueries.some((nextQuery: string) => {
        return findQueryIncludedInNextQueries(nextQuery, query);
      });
    })
    .map(relatedprompt => {
      return {
        ...relatedprompt,
        nextQueries: relatedprompt.nextQueries.sort((a, b) => {
          const foundInA = findQueryIncludedInNextQueries(a, query);
          const foundInB = findQueryIncludedInNextQueries(b, query);

          if ((foundInA && foundInB) || (!foundInA && !foundInB)) {
            return 0;
          }

          return foundInA ? -1 : 1;
        })
      };
    });
}

export const relatedPrompts = endpointAdapterFactory<any, any>({
  endpoint: getRelatedPromptEndpoint(),
  responseMapper: schemaMapperFactory<any, any>({
    query: (_response, request) => request?.requestParameters?.query ?? '',
    syntheticSuggestions: (response, request) =>
      syntheticSuggestionsMapper(
        (response.data?.relatedprompts as Array<MyMotiveRelatedPrompt>) ?? [],
        (request?.requestParameters?.query as string) ?? '',
        (request?.requestParameters?.fallbackQuery as string) ?? ''
      )
  })
});
